import jQuery from 'jquery'
import $ from 'jquery'

var slick = require('imports-loader?$=jquery!slick-carousel');

import * as helpers from './helpers';
import mobilesettings from './mobilesettings';

export default {

    /*
     * Settings
     * */
    settings: {
        searchBox: '#searchbox',
        searchTrigger: '.trigger-search',
        searchCancel: '.searchbox__form__reset',
        searchVisibleClass: 'has-search-visible',
        navigationWrapper: '.navigation-wrapper',
        navigationTrigger: '.trigger-mainNav',
        navigationContainer: '.mainNav',
        navVisibleClass: 'has-nav-visible',
        navToggleSpeed: 250,
        boxHigh: '.box--high',
        boxLow: '.box--low',
        boxHighRatio: 390/288,
        boxLowRatio: 150/288,
        boxHighBaseHeight: 390,
        boxLowBaseHeight: 150,
        boxBaseWidth: 288,
        iconBasePath: 'fileadmin/typoleton/img/icons/',
    },

    currentIcons : [0, 1, 2],
//    eles: {
//      $searchTrigger : $(this.settings.searchTrigger),
//      $navWrapper : $(bittner.settings.navigationWrapper),
//    },
    init: function ($dom) {
        var self = this;
//      var els = this.eles;

        //console.log(self);
        //console.log(helpers.getResponsiveTag());
        //console.log(helpers.isMobile());
//      console.log(els);

        self.setBgImage();
        self.bindClickEvents();
        self.buildSlider();
        self.setSearchHeight();
        self.bindScrollTopLink();

    },
    bindReferences: function($dom) {
        var self = this;
//      var els = this.eles;

    },
    toggleSearch: function () {
        var self = this;
//      var els = this.eles;

    },
    toggleNav : function ($dom) {
        var self = this;

        var $navContainer = $(self.settings.navigationContainer);
        var $navWrapper = $(self.settings.navigationWrapper);
        var $navTrigger = $(self.settings.navigationTrigger);
        var $searchBox = $(self.settings.searchBox);
        var isNavOpen = $navWrapper.hasClass(self.settings.navVisibleClass)  ? true : false;

        //if (helpers.isMobile() == true) {
            $searchBox.hide();
            if ( isNavOpen ) {
                $searchBox.hide();
                $navContainer.slideUp(self.settings.navToggleSpeed);
                $navTrigger.find('i.fa').toggleClass('fa-chevron-down').toggleClass('fa-chevron-up');
                $navWrapper.removeClass(self.settings.navVisibleClass);
                isNavOpen = false;
            } else { // nav closed
                $navContainer.slideDown(self.settings.navToggleSpeed);
                setTimeout(function () {
                    $searchBox.show();
                }, self.settings.navToggleSpeed);
                $navTrigger.find('i.fa').toggleClass('fa-chevron-down').toggleClass('fa-chevron-up');
                $navWrapper.addClass(self.settings.navVisibleClass);
                isNavOpen = true;
            }
        // } else {
        //     console.log('is not mobile');
        // }

    },
    setSearchHeight: function () {
        var self = this;

        var targetheight = $(self.settings.navigationContainer).innerHeight() + 3; //3 borders
        $(self.settings.searchBox).css('top', targetheight + 'px');

    },
    bindClickEvents : function (dom) {
        var self = this;
//      var els = this.eles;

        $(self.settings.searchTrigger).on('click', function (e) {
            e.preventDefault();
            $(self.settings.navigationWrapper).toggleClass(self.settings.searchVisibleClass);
        });
        $(self.settings.searchCancel).on('click', function (e) {
//        e.preventDefault();
            $(self.settings.navigationWrapper).toggleClass(self.settings.searchVisibleClass);
        });
        $(self.settings.navigationTrigger).on('click', function (e) {
            e.preventDefault();

            self.toggleNav();
        })
    },

    setBoxHeights : function () {
        var self = this;

        //console.log('set box heights');

        var $lowBoxes = $(self.settings.boxLow);
        var $highBoxes = $(self.settings.boxHigh);

//       if (helpers.getResponsiveTag() === mobilesettings.Utils.PHONE_TAG || helpers.getResponsiveTag() === mobilesettings.Utils.PHABLET_TAG) {
        if (helpers.getResponsiveTag() === mobilesettings.Utils.PHONE_TAG ) {
            var boxWidth = $lowBoxes.first().outerWidth() || $highBoxes.first().outerWidth();
            var boxScaleFactor = boxWidth / self.settings.boxBaseWidth ;

            $lowBoxes.each(function (i, e) {
                $(e).css('height', boxWidth * self.settings.boxLowRatio * boxScaleFactor);
            });
            $highBoxes.each(function (i, e) {
                $(e).css('height', boxWidth * self.settings.boxHighRatio * boxScaleFactor);
            });
        } else {
            $lowBoxes.each(function (i, e) {
                $(e).css('height', '');
            });
            $highBoxes.each(function (i, e) {
                $(e).css('height', '');
            });
        }
    },

    flipHeadIcons : function ($dom) {
        var self = this;
        var allIcons = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
//      var currentIcons = [1, 2, 3];

        var iconToSwap = self.selectIconContainer(self.currentIcons);
        var $iconToSwap = $('#head-icon-'+iconToSwap);

        var newIcon = self.selectIcon(self.currentIcons, allIcons);

//      var newIcon = helpers.randomIntFromInterval(1, icons.length);

        //console.log(iconToSwap);
        //console.log(newIcon);

        if ($iconToSwap.hasClass('flipped')) {
            $iconToSwap.find('.front').attr('src', self.settings.iconBasePath + newIcon + '.png');
        } else {
            $iconToSwap.find('.back').attr('src', self.settings.iconBasePath + newIcon + '.png');
        }
        self.currentIcons[iconToSwap] = newIcon;
        //console.log(self.currentIcons);
        setTimeout(function () {
            $iconToSwap.toggleClass('flipped');
        }, 20);


    },

    selectIconContainer : function (currentIcons) {
        var self = this;

        var selectedIcon = helpers.randomIntFromInterval(0, currentIcons.length - 1);
        return selectedIcon;
    },

    selectIcon : function (currentIcons, allIcons) {
        var self = this;

        var possibleIcons = allIcons;

        var length = currentIcons.length;
        for(var i = 0; i < length; i++) {
            var index = possibleIcons.indexOf(currentIcons[i]);
            if (index > -1) {
                possibleIcons.splice(index, 1);
            }
        }

        var newIconIndex = helpers.randomIntFromInterval(0, possibleIcons.length - 1);

        return possibleIcons[newIconIndex];
    },


    buildSlider : function ($dom) {
        var self = this;

        $('.media.slider').slick({
            slide:'.image',
            adaptiveHeight: true
        });
    },

    setBgImage: function ($dom) {
        var self = this;

        var $bgImageNode = $('#bgimage');

        var bgImages = $bgImageNode.data('bgimages').split(';');
        bgImages.splice(bgImages.length - 1, 1);

        var imgIndex = helpers.randomIntFromInterval(0, bgImages.length - 1);

        var bgImage = bgImages[imgIndex];

        $bgImageNode.css('background-image', 'url(/'+bgImage+')');
    },

    bindScrollTopLink: function () {
        var self = this;

        var $scrollTopLink = $('.back-to-top-link');

        $scrollTopLink.on('click', function (event) {
            event.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, 800);
            return false;
        })
    }
};
