"use strict";

require('smoothscroll-polyfill').polyfill();
// require('something-that-does-not-exist'); // produce an error

const defaults = {
    selectors: {
        scrollLink: '.js-scroll-link',
    },
    settings: {
        scrollDuration: 400,
    }
};

export default function bindScrollLinks() {
    let allScrollLinks = document.querySelectorAll(defaults.selectors.scrollLink);

    if (!allScrollLinks) return false;

    Array.from(allScrollLinks).forEach(scrollLink => {

        scrollLink.addEventListener('click', function (event) {
            // console.log(event);
            event.preventDefault();
            let targetHash = event.target.hash;

            if (!targetHash) {
                return false;
            }

            let target = document.querySelector(targetHash);
            // console.log(target);

            if (!target) {
                return false;
            }

            target.scrollIntoView({ behavior: 'smooth' });
        })
    });
}
