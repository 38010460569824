// require("./sass/app.scss");
import docReady from 'doc-ready';

import { lang } from './components/helpers.js'

import Modernizr from 'modernizr';

import 'es6-promise/auto';

require('array.prototype.find').shim();
require('smoothscroll-polyfill').polyfill();

import bittner from './components/bittner.js'

import initNotifications from './components/notifications';

import bindArrowToTop from './components/arrow-to-top';
import bindScrollLinks from './components/scroll-links';

import {initTables} from "./components/tables";
import {initMaps} from "./components/maps";

import initConsentManager from "./vbcn";

function initGlobal() {

    console.log('Language from global: ' + lang);

    //GLOBAL
    bittner();
    initNotifications();
    bindArrowToTop();
    bindScrollLinks();
    initConsentManager();
    initTables();
    initMaps();
}

export default function () {
    initGlobal();
}

