function checkMaps() {
    if(window.cookieState && window.cookieState.cookiesAllowed && window.cookieState.allowedData.options.hasOwnProperty('maps') && window.cookieState.allowedData.options['maps'] ) {
        $('.maps-embed').each(function(index) {

            var iframe = $(this).find('iframe');

            $(this).show();
            iframe.attr('src', iframe.attr('data-src'));
        });
    }
}

export function initMaps() {
    // console.log("initMaps");
    checkMaps();
    $('.js-vbcn-execute, #vbcn-accept-preset, #vbcn-save').on('click', initMaps);
}
