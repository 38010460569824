"use strict";

export const scriptsStack = {
    mandatory: []
};

export function collectScripts() {
    let allScriptTags = document.querySelectorAll('script[type="x-script/vbcn"]');

    Array.from(allScriptTags).forEach(scriptTag => {
        var scriptType = scriptTag.dataset.type;
        var subType = scriptTag.src !== "" ? 'src' : 'inline';

        if (scriptsStack.hasOwnProperty(scriptType)) {
            scriptsStack[scriptType].push({type: subType, tag: scriptTag});
        } else {
            scriptsStack[scriptType] = [];
            scriptsStack[scriptType].push({type: subType, tag: scriptTag});
        }
    });

    let allIframes = document.querySelectorAll('.js-vbcn-iframe[data-src]');

    Array.from(allIframes).forEach(iframe => {
        var type = iframe.dataset.type;
        var subType = 'iframe';

        if (scriptsStack.hasOwnProperty(type)) {
            scriptsStack[type].push({type: subType, tag: iframe});
        } else {
            scriptsStack[type] = [];
            scriptsStack[type].push({type: subType, tag: iframe});
        }
    });

    console.log(scriptsStack);
}

export function evalScript(elem) {
    var data = (elem.text || elem.textContent || elem.innerHTML || ""),
        parent = elem.parentNode,
        script = document.createElement("script");

    script.type = "text/javascript";
    try {
        // doesn't work on ie...
        script.appendChild(document.createTextNode(data));
    } catch (e) {
        // IE has funky script nodes
        script.text = data;
    }

    Array.from(elem.attributes).forEach(attribute => {
        if(attribute.name !== "type" && attribute.name !== "src") {
            script.setAttribute(attribute.name, attribute.value)
        }
    });

    if (script.parentNode) {
        script.parentNode.removeChild(script);
    }

    if(parent) {
        parent.insertBefore(script, elem);
        parent.removeChild(elem);
    }
}

export function loadScript(elem) {
    var src = (elem.src || ""),
        parent = elem.parentNode,
        script = document.createElement("script");

    script.type = "text/javascript";
    script.src = src;

    Array.from(elem.attributes).forEach(attribute => {
        if(attribute.name !== "type" && attribute.name !== "src") {
            script.setAttribute(attribute.name, attribute.value)
        }
    });

    if (script.parentNode) {
        script.parentNode.removeChild(script);
    }

    if(parent) {
        parent.insertBefore(script, elem);
        parent.removeChild(elem);
    }
}

export function loadIframe(elem) {
    if(elem.dataset.src) {
        elem.src = elem.dataset.src;
        elem.removeAttribute('data-src');
    }
}

export function executeScripts(scriptType) {
    if (!scriptType) {
        return false;
    }
    if (scriptType === "all" ) {
        for (var type in scriptsStack) {
            if (scriptsStack.hasOwnProperty(type)) {
                scriptsStack[type].forEach((script, i) => {
                    if(script.type === "inline") {
                        evalScript(script.tag);
                    }
                    if(script.type === "src") {
                        loadScript(script.tag);
                    }
                    if(script.type === "iframe") {
                        loadIframe(script.tag);
                    }
                });
            }
        }
    } else {
        if(scriptsStack.hasOwnProperty(scriptType)) {
            scriptsStack[scriptType].forEach((script, i) => {
                if(script.type === "inline") {
                    evalScript(script.tag);
                }
                if(script.type === "src") {
                    loadScript(script.tag);
                }
                if(script.type === "iframe") {
                    loadIframe(script.tag);
                }
            });
        }
    }
}
