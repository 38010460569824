export const cookieSettings = {
    cookiename: "cookiesettings",
    cookieLifeTime: 1, // in months
};

export const cookieNoticeSettings = {
    centerMode: false,
    ignoredPages: [
        '/datenschutz',
    ],
    hasDeclineButton: false,
    // backgroundColor: 'blue',
    // textColor: 'red',
    useBackdrop: false,
    backdropColor: '#333',
    backdropOpacity: '0.8',
    autoEnableMandatory: false,
    autoEnableMandatoryOnPlaceholder: true,
};

export const cookieNoticeTexts = {
    "de": {
        initialHeadline: 'Cookies, externe Dienste & Datenschutz',
        customizeHeadline: 'Ihre Datenschutz-Einstellungen',
        explanationText: "Nach Ihrer Zustimmung verwenden wir Cookies um die Anzeige zu optimieren. Zur Analyse der Zugriffe auf unsere Website verwenden wir Matomo Analytics. Außerdem binden wir Skripte von Google Maps ein. Dabei können personenbezogene Daten an diese Anbieter übermittelt werden. Bitte klicken Sie <a href='/datenschutzerklaerung.html'>hier</a>, um Informationen zum Datenschutz zu erhalten.",
        accept_preset_ButtonText: 'Alle Akzeptieren',
        declineButtonText: 'Ablehnen und schließen',
        accept_all_ButtonText: 'Alle Cookies Akzeptieren',
        decline_all_ButtonText: 'Alle Ablehnen',
        customizeButtonText: 'Einstellungen',
        cancel_customizeButtonText: 'Abbrechen',
        saveButtonText: 'Speichern',
        closeButtonText: '×'
    },
    "en": {
        initialHeadline: 'Accept cookies & Privacy Policy?',
        customizeHeadline: 'Select cookies to accept',
        explanationText: "we use cookies. learn more <a href='/datenschutzerklaerung.html'>here</a>",
        accept_preset_ButtonText: 'Accept all',
        declineButtonText: 'Decline and close',
        accept_all_ButtonText: 'Accept all',
        decline_all_ButtonText: 'Decline all',
        customizeButtonText: 'Customize',
        cancel_customizeButtonText: 'Cancel',
        saveButtonText: 'Save',
        closeButtonText: '×'
    }
};

export const cookieSets = {
    "de": [
        {
            id: 'mandatory',
            name: 'Notwendige Cookies',
            description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Sie speichern keine personenbezogenen Daten.',
            mandatory: true,
            mandatory_text: "Diese Cookies sind notwendig, um Ihre Einstellungen zu speichern."
        }, {
            id: 'matomo',
            name: 'Matomo Analytics',
            description: 'Diese Skripte und Cookies werden eingebunden, um mehr über die Besucher unserer Website zu erfahren - wie zum Beispiel Herkunft, Bildschirmauflösung oder verwendeter Browser.'
        }, {
            id: 'maps',
            name: 'Google Maps',
            description: 'Diese Skripte und Cookies sind nötig, um die Karten des Dienstes Google Maps darzustellen. Durch das Aktivieren können personenbezogene Daten an Google übertragen werden.'
        }],
    "en": [
        {
            id: 'mandatory',
            name: 'Mandatory cookies',
            description: 'Functional scripts are there so the website works as expected',
            mandatory: true,
            mandatory_text: "Dies muss gemacht werden."
        }, {
            id: 'matomo',
            name: 'Matomo Analytics',
            description: 'Diese Skripte und Cookies werden eingebunden, um mehr über die Besucher unserer Website zu erfahren - wie zum Beispiel Herkunft, Bildschirmauflösung oder verwendeter Browser.'
        }, {
            id: 'maps',
            name: 'Google Maps',
            description: 'Diese Skripte und Cookies sind nötig, um die Karten des Dienstes darzustellen. Durch das Aktivieren können personenbezogene Daten an [[ Google / Mapbox ]] übertragen werden.'
        }]
};
