import jQuery from 'jquery'
import $ from 'jquery'

var slick = require('imports-loader?$=jquery!slick-carousel');
import * as helpers from './helpers';
import mobilesettings from './mobilesettings';

import bittnerFns from './bittner-fns';

export default function  () {






    /*
     * Main Docready block
     * ===================================================
     * */

    $(document).ready(function() {

        bittnerFns.init($(document));

        $("#Anfrageformular").attr('action',$("#Anfrageformular").attr('action') + "#formanker");




        var scrollHandlers = helpers.debounce(function() {
            var st = $(window).scrollTop();
            var wh = $(window).innerHeight();




        }, 10);

        $(window).on('scroll', scrollHandlers);


        var resizeHandlers = helpers.debounce(function() {
            var st = $(window).scrollTop();
            var wh = $(window).innerHeight();


            bittnerFns.setBoxHeights();


        }, 10);

        $(window).on('resize', resizeHandlers);

        var tickHandlers = setInterval(function() {
            bittnerFns.flipHeadIcons();
        }, 3000);

    });



    //$.fn.extend(gcat);

    /*
     * --- END Docready ---
     * */


    // $(window).scroll(function () {
    //     if ($(this).scrollTop() > 450) {
    //         $('.back-to-top-link').css({'visibility': 'visible', 'opacity': '1'});
    //     } else {
    //         $('.back-to-top-link').css({'visibility': 'hidden', 'opacity': '0'});
    //     }
    // });
    //
    // $(window).load(function() {
    //
    //     $(window).trigger('resize');
    //     $(window).trigger('scroll');
    //
    // }); // --- END window load ---
    //

}
