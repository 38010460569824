export default {
    Utils : {
        PHONE_TAG : "phone",
        PHABLET_TAG : "phablet",
        TABLET_TAG : "tablet",
        DESKTOP_TAG : "desktop",
        DESKTOP_LARGE_TAG : "wide",
    }
};

